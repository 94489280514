<template>
  <div id="app">
    <pagePc v-if="isPc"/>
    <pageMobile v-if="isMobile"/>
  </div>
</template>

<script>
import pagePc from './components/pagePc.vue'
import pageMobile from './components/pageMobile.vue'
export default {
  name: 'App',
  components: {
    pagePc,
    pageMobile
  },
  data(){
    return{
        isPc:false,
        isMobile:false
    }
  },
  created(){
    //判断客户端
        var isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i) ? true : false;
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i) ? true : false;
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
            },
            Windows: function () {
                return navigator.userAgent.match(/IEMobile/i) ? true : false;
            },
            any: function () {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
            }
        };
        if (isMobile.any()) {
            console.log('mobile')
            this.isMobile = true
            this.isPc = false
        } else {
            console.log('pc')
            this.windowResize()
            window.removeEventListener('resize', this.windowResize)
            window.addEventListener('resize', this.windowResize)
        }
  },
  methods:{
     windowResize(){
        if (window.innerWidth <= 1024) {
            this.isMobile = true
            this.isPc = false
        }else{
            this.isMobile = false
            this.isPc = true
        }
         
    }
  }
}
</script>
