<template>
    <div class="pageMobile">

        <ul class="rightFixedBtn">
            <el-popover   placement="left"  trigger="hover" popper-class="hidePop resetPop2 animate__animated  animate__faster animate__fadeInRight2" >
                 <div class="online">
                    <p class="title"><img src="@/assets/images/icon_dianhuakefu.png" /> 24小时咨询电话</p>
                    <p class="txt tel">400-1868-555</p>
                    <p class="title" @click="openUrl(7)"><img src="@/assets/images/icon_zaixiankefu.png" /> 客服在线咨询</p>
                    <p class="txt" @click="openUrl(7)">专属客服为您服务</p>
                 </div>
                <li class="flex animate" slot="reference"><img src="@/assets/images/index_icon_weixin2.png" />在线客服</li>
            </el-popover>
            <el-popover   placement="left"  trigger="hover" popper-class="hidePop animate__animated  animate__faster animate__fadeInRight2 el-popover2" >
                <img class="cgjWx" src="@/assets/images/code/gzh.jpg" />
                <li class="flex animate" slot="reference"><img src="@/assets/images/index_icon_weixin.png" />公众号</li>
            </el-popover>
            <!-- <el-popover   ref="xcxPop" placement="left"  trigger="hover" popper-class="hidePop animate__animated  animate__faster animate__fadeInRight2  el-popover2 " >
                <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                <li class="flex" slot="reference"><img src="@/assets/images/index_icon_xcx.png" />小程序</li>
            </el-popover>
            <li class="flex" v-if="scollTopBtnShow"  @click="scrollTop()"><img src="@/assets/images/index_icon_up.png" />返回顶部</li> -->
        </ul>
        <div class="bottomBtn">
            <div>买车、养车、用车小程序全搞定 <div class="btn" @click="openUrl(0)">立即体验<i class="el-icon-arrow-right"></i></div></div>
        </div>

         <div class="top">
            <div class="head flex flexBetween">
                <div class="logo">
                    <img src="@/assets/images/mobile/wap_hxcgj_logo.png" title="恒信车管家" />
                </div>
                <div class="links">
                    <el-popover v-model="linksShow" placement="bottom" popper-class="pagelinks" trigger="click">
                        <i class="el-icon-menu" slot="reference"></i>
                        <div class="linksUl">
                            <a href="#top" class="active" @click.stop="linksShow = false">首页</a>
                            <a href="#buyCar"  @click.stop="linksShow = false">买车</a>
                            <a href="#MAINTENANCE"  @click.stop="linksShow = false">养车</a>
                            <a href="#centerLeft"  @click.stop="linksShow = false">车生活</a>
                        </div>
                     </el-popover>
                </div>
            </div>
        </div>


         <div class="banner" id="top">
            <el-carousel class="bannerCarousel" ref="elCarousel" height="1414rem" :interval="bannerChangeTime" :loop="true" @change="bannerChange">
                <el-carousel-item>
                    <div class="bannerCenter">
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 0">恒信车管家</h5>
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 0">您身边的汽车管家</h5>
                    </div>
                    <div class="blcakBg"></div>
                    <div class="bg1">
                         <video ref="videoPlayer" class="Video" id="video" loop="true" muted autoplay="true" controlslist="nodownload" object-fit:fill
                webkit-playsinline playsinline x5-video-player-type="h5" x5-video-orientation="portraint">
                                <source src="@/assets/video/banner.mp4" type="video/mp4">
                                你的浏览器不支持HTML5 video.
                        </video>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                     <div class="bannerCenter">
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 1">特价精品车</h5>
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 1">超值高配低价准新车</h5>
                    </div>
                    <div class="bg2"></div>
                </el-carousel-item>
                <el-carousel-item>
                     <div class="bannerCenter">
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 2">恒信惠保</h5>
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 2">超高性价比维修保养套餐</h5>
                    </div>
                    <div class="bg3"></div>
                </el-carousel-item>
            </el-carousel>
        </div>

         <div class="p1" >

                <div class="title">
                    <p ref="item7" class="title1 animate__animated  animate__faster animate__fadeInUp" v-show="item7Show">恒信车管家</p>
                    <p ref="item8" class="title2 animate__animated  animate__faster animate__fadeInUp animate__delay-2s" v-show="item8Show">小程序上线啦!</p>
                    <p class="title3 animate__animated  animate__faster animate__fadeInUp animate__delay-3s" v-show="item8Show">全国领先的一站式汽车全方位服务平台</p>
                </div>

                <div class="itemsCenter flex"><img src="@/assets/images/mobile/wap_screen_shouye.png" /></div>

                <div class="items flex flexBetween">

                        <div class="left">
                            <div class="centerLeft">
                            <h5 class="title1">CAR BUTLER</h5>
                            <div class="line">
                                <div class="line1"></div>
                                <div class="line2"></div>
                            </div>
                             <h5 class="title2">车管家</h5>
                        </div>
                        
                        <div v-show="item1Show" ref="item1" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                            <div class="board" @click="openUrl(6)">
                                <div :class="['itemShow1 animate__animated']">
                                            <img src="@/assets/images/chahua_zsgjhfw.png" />
                                            <p>专属管家好服务</p>
                                            <p>1对1专属管家</p> 
                                </div> 
                                <div :class="['itemShow2 animate__animated']">
                                            <img src="@/assets/images/code/601.png" />
                                            <p>扫码体验</p>
                                            <p></p>
                                </div>
                            </div>
                        </div>
                        <div v-show="item2Show" ref="item2"  class="itemDetail flex  animate__animated  animate__faster animate__fadeInUp">
                            <div class="board" @click="openUrl(2)">
                                <div :class="['itemShow1 animate__animated']">
                                            <img src="@/assets/images/chahua_mclctmh.png" />
                                            <p>买车流程透明化</p>
                                            <p>随时随地了解买车进度</p>
                                </div> 
                                <div :class="['itemShow2 animate__animated']">
                                             <img src="@/assets/images/code/602.png" />
                                            <p>扫码体验</p>
                                            <p></p>
                                </div>
                            </div>
                        </div>
                        <div v-show="item3Show" ref="item3"  class="itemDetail flex  animate__animated  animate__faster animate__fadeInUp">
                            <div class="board" @click="openUrl(5)">
                                <div :class="['itemShow1 animate__animated']">
                                    <img src="@/assets/images/chahua_scsjzfb.png" />
                                    <p>试乘试驾真方便</p>
                                    <p>无需到店即可预约试乘试驾</p>
                                </div> 
                                <div :class="['itemShow2 animate__animated']">
                                    <img src="@/assets/images/code/603.png" />
                                    <p>扫码体验</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>

                        

                    </div>

                    <div class="right">
                        <div v-show="item4Show" ref="item4" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                                <div class="board" @click="openUrl(4)">
                                    <div :class="['itemShow1 animate__animated']">
                                        <img src="@/assets/images/chahua_yybyxyh.png" />
                                        <p>预约保养享优惠</p>
                                        <p>足不出户帮您在线预约保养</p>
                                    </div> 
                                    <div :class="['itemShow2 animate__animated']">
                                        <img src="@/assets/images/code/604.png" />
                                        <p>扫码体验</p>
                                        <p></p>
                                    </div>
                                </div>   
                        </div>
                        <div v-show="item5Show" ref="item5" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                            <div class="board" @click="openUrl(1)">
                                    <div :class="['itemShow1 animate__animated']">
                                        <img src="@/assets/images/chahua_czqyzsj.png" />
                                        <p>车主权益再升级</p>
                                        <p>提高客户车辆资产安全性</p>
                                    </div> 
                                    <div :class="['itemShow2 animate__animated']">
                                        <img src="@/assets/images/code/606.png" />
                                        <p>扫码体验</p>
                                        <p></p>
                                    </div>
                                </div>   
                        </div>
                        <div v-show="item6Show" ref="item6" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                            <div class="board" @click="openUrl(3)">
                                    <div :class="['itemShow1 animate__animated']">
                                        <img src="@/assets/images/chahua_ycxftmh.png" />
                                        <p>养车消费透明化</p>
                                        <p>维修保养车辆消费透明化</p>
                                    </div> 
                                    <div :class="['itemShow2 animate__animated']">
                                        <img src="@/assets/images/code/605.png" />
                                        <p>扫码体验</p>
                                        <p></p>
                                    </div>
                                </div>   
                        </div>
                        <div class="more">
                            <p>更多精彩</p>
                            <p>等你探索</p>
                        </div>
                    </div>

                </div>
            </div>
            <div  id="buyCar"></div>
           <div class="pageBase p2" >
                <div class="center">
                    <div class="centerLeft animate__animated  animate__faster animate__fadeInUp"  ref="item9" v-show="item9Show">
                        <h5 class="title1  animate__animated  animate__faster animate__fadeInUp">BUY A CAR</h5>
                        <div class="line  animate__animated  animate__faster animate__fadeInUp">
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                        <h5 class="title2  animate__animated  animate__faster animate__fadeInUp animate__delay-1s">新车销售</h5>
                        <p class="title3  animate__animated  animate__faster animate__fadeInUp animate__delay-2s">国内领先的大型汽车经销集团，超300家汽车4S门店，为您提供专业为消费者提供新车、维保、个性化改装、精品定制、汽车美容的服务</p>
                    </div>
                    <div class="right"  v-show="item9Show">
                             <el-carousel height="870rem" indicator-position="outside" :loop="true" :interval="5000">
                                <el-carousel-item><img class="showImg" src="@/assets/images/mobile/wap_screen_xinche.png"   /></el-carousel-item>
                                <el-carousel-item><img class="showImg" src="@/assets/images/mobile/wap_screen_tejiache.png"   /></el-carousel-item> 
                                <el-carousel-item><img class="showImg" src="@/assets/images/mobile/wap_screen_ershouche.png"   /></el-carousel-item> 
                            </el-carousel>
                            <div class="bg"></div>
                    </div>
                </div>
            </div>

            <div class="pageBase p3" id="MAINTENANCE">
                <div class="center">
                    <div class="centerLeft animate__animated  animate__faster animate__fadeInUp"  ref="item10" v-show="item10Show">
                        <h5 class="title1">MAINTENANCE CAR</h5>
                        <div class="line">
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                        <h5 class="title2">安心养车</h5>
                        <p class="title3">在线预约，方便快捷，专享折扣回馈<br/>车管家会员专享特惠保养套餐，一键购入</p>
                    </div>
                    <div class="right">
                             <el-carousel height="870rem" indicator-position="outside" :loop="true" :interval="5000">
                                <el-carousel-item><img class="showImg" src="@/assets/images/mobile/wap_screen_yangche01.png"   /></el-carousel-item>
                                <el-carousel-item><img class="showImg" src="@/assets/images/mobile/wap_screen_yangche02.png"   /></el-carousel-item>
                                <el-carousel-item><img class="showImg" src="@/assets/images/mobile/wap_screen_yangche03.png"   /></el-carousel-item> 
                            </el-carousel>
                     </div>
                </div>
            </div>

            <div class="pageBase p3" id="centerLeft">
                <div class="center" >
                    <div class="centerLeft animate__animated  animate__faster animate__fadeInRight" >
                        <h5 class="title1">CAR LIFE</h5>
                        <div class="line">
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                        <h5 class="title2">恒信车生活</h5>
                        <p class="title3">车主每日抽奖领好礼，积分兑换超值商品，享受多项车主福利。车友圈炫新车、聊故事、谈改装，打造车友社交新方式！</p>
                    </div>
                    <div class="right p4 animate__animated  animate__faster animate__fadeInRight"  >
                             <img class="showImg" src="@/assets/images/mobile/wap_screen_cheshenghuo.png"   />
                     </div>
                </div>
            </div>
            <div class="copyright flex">Copyright © 2023 恒信汽车集团 版权所有</div>
    </div>
</template>

<script>
import 'animate.css';
export default {
  name: 'pagePc',
  data(){
    return{
        linksShow:false,

        activeTab:0,
        bannerIndex:0,
        bannerChangeTime:16000,

        item1Show: false,
        item2Show: false,
        item3Show: false,
        item4Show: false,
        item5Show: false,
        item6Show: false,
        item7Show: false,
        item8Show: false,
        item9Show: false,
        item10Show: false,

        isScrolling:false,
        timerT:null,
        timer2:null,
        scollTopBtnShow:false,
        showXcx:true,
        openLinks:[
            'https://mobile.hxqc.com/j/ic',
            'https://mobile.hxqc.com/j/mc',
            'https://mobile.hxqc.com/j/kc',
            'https://mobile.hxqc.com/j/yc',
            'https://mobile.hxqc.com/j/rc',
            'https://mobile.hxqc.com/j/tc',
            'https://mobile.hxqc.com/j/sc',
            'https://chat.pacss.hxqcgf.com/chat/mobile?noCanClose=1&token=3e863cf73eb77f2ce8774fbaa26ebef1'
        ]
    }
  },
  beforeDestroy(){
  },
  mounted(){
    
            
            var docEl = document.documentElement,
                resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
                recalc = function () {
                    var clientWidth = docEl.clientWidth;
                    if (clientWidth > 750) {
                        clientWidth = 750;
                        docEl.style.fontSize = 67.5 + 'px';
                    }
                    if (!clientWidth) return;
                    docEl.style.fontSize =  (clientWidth / 750) + 'px';
                };
            recalc();
            if (!document.addEventListener) return;
            window.addEventListener(resizeEvt, recalc, false);
            document.addEventListener('DOMContentLoaded', recalc, false);

             window.addEventListener('scroll', this.handleScroll)
             this.handleScroll()
            
             this.slideBanner()

         /*    this.$refs.xcxPop.showPopper = true
            this.timer2 = setTimeout(()=>{
                this.$refs.xcxPop.showPopper = false
                clearTimeout(this.timer2)
                this.timer2 = null
            },5000) */
  },
  methods:{

    openUrl(index){
        window.open(this.openLinks[index])
    },
    scrollTop(){
        let i = 10
        var timer= setInterval(function () {
            if (document.documentElement.scrollTop != 0) {
                document.documentElement.scrollTop -= (100 + i);
                i = i+10
             } else {
                clearInterval(timer);
            }
        }, 10);
    },


    // 轮播手滑切换
        slideBanner() {
            
            let that = this
            //选中item的盒子
            var box = document.querySelector('.bannerCarousel')
			if(box === null){
				return
			}
            //手指起点X坐标
            var startPoint = 0
            //手指滑动重点X坐标
            var stopPoint = 0
 
            //重置坐标
            /* var resetPoint = function () {
                startPoint = 0
                stopPoint = 0
            } */

			let hasMove = false
 
            //手指按下
            box.addEventListener('touchstart', function (e) {
                //手指按下的时候停止自动轮播
                //手指点击位置的X坐标
                startPoint = e.changedTouches[0].pageX
                stopPoint = e.changedTouches[0].pageX
            })
            //手指滑动
            box.addEventListener('touchmove', function (e) {
				hasMove = true
                //手指滑动后终点位置X的坐标
                stopPoint = e.changedTouches[0].pageX
            })
            //当手指抬起的时候，判断图片滚动离左右的距离
            box.addEventListener('touchend', function () {
                if (startPoint - stopPoint > 80 && hasMove) {
					// 左滑
					that.$refs.elCarousel.next()
                    return
                }
                if (startPoint - stopPoint < -80 && hasMove) {
					// 右滑
					that.$refs.elCarousel.prev()
                    return
                }
                stopPoint = 0
            })
        },

    bannerChange(e){
        this.bannerIndex = e
        if (e == 0){
            this.$refs.videoPlayer.load()
            this.bannerChangeTime = 16000
        }else{
            this.bannerChangeTime = 5000
        }
    },
 
    handleScroll () {
        if (this.isScrolling == false){
            this.isScrolling = true
            // 实现当滚动到指定位置，触发动画
            // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
            // this.gdjz('earth', 'earth animated bounceInDown', 50)
            let _this = this
            let refArray = [
                    {ref: 'item1', show: 'item1Show'},
                    {ref: 'item2', show: 'item2Show'},
                    {ref: 'item3', show: 'item3Show'},
                    {ref: 'item4', show: 'item4Show'},
                    {ref: 'item5', show: 'item5Show'},
                    {ref: 'item6', show: 'item6Show'},
                    {ref: 'item7', show: 'item7Show'},
                    {ref: 'item8', show: 'item8Show'},
                    {ref: 'item9', show: 'item9Show'},
                    {ref: 'item10', show: 'item10Show'},
            ]

            refArray.forEach((r) => {
                    _this.gdjz(r.ref, 10, () => {
                        _this[r.show] = true
                    },()=>{
                        //_this[r.show] = false
                    })
            })

            clearTimeout(this.timerT)
            this.timerT = setTimeout(()=>{
                this.isScrolling = false
            },1000)
             
        }
    },
    gdjz (div, offset, show,hide) {
      let dom = this.$refs[div] // 等同于document.querySelector('.earth') true
      if(dom){
        var a,b,c,d;
        d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset)
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
         if( b + c > a ) {
            show && show()
        }else{
            hide && hide()
        }
      }
    },

   
    
  }
}
</script>
<style>
.resetPop2{ width: 300rem; height: 260rem;border-radius: 10rem;}
.el-popover2{display:flex;justify-content:center;align-items:center; padding:24rem;box-sizing:border-box;width: 360rem;height:360rem;min-width: 200rem;}
.pagelinks{ width: 300rem; height: 390rem;}
.pagelinks .linksUl{ width:100%;  }
.pagelinks .linksUl a{display: block; width: 300rem; font-size: 36rem;cursor: pointer;padding:20rem 0; border-bottom: 1px solid #eee; text-align: center; text-decoration: none; color:#333;}
.pagelinks .linksUl a:hover,li.active{color: #CE1C24; }
.pagelinks .linksUl a:last-child{border:none;}
    
</style>
<style lang="less" scoped>
.bottomBtn{ position: fixed; top:auto; bottom:0; left:0; right:0; z-index: 1000;
    >div{ max-width: 750rem;position: relative; margin: auto;  height: 96rem;background-color: #A10007; color: #fff; font-size: 32rem; line-height: 96rem; text-indent: 40rem;
        .btn{ background:#CE1C24; position: absolute; left:auto; right:0; top:0; bottom:0; width: 216rem; text-align: center;text-indent: 0;}
    }
}
.pageMobile{width:750rem;margin: auto;}
.top{ position:fixed;z-index:10;background-color:#fff;left:0;top:0;right:0;width: 100%;
        .head{margin: auto;padding:28rem 42rem; margin: auto;box-sizing: border-box;border-bottom: 1px solid #f0f0f0; 
            .logo{img{width: 160rem;}}
            .links{
                i{font-size: 44rem; color: #999999;}
            }
        }
}


                
.banner{width:750rem;height: 1414rem; margin: auto; overflow: hidden; margin-top: 121rem;
    .bannerCenter{color: #fff; width: 750rem; margin: 1100rem auto 0 64rem; z-index: 1;
        h5{line-height: 1; font-size: 56rem; margin-bottom: 30rem;letter-spacing:5rem;}
    }
    .bg1,.bg2,.bg3,.bg4,.blcakBg{ width: 750rem; background-image: url(../assets/images/mobile/wap_banner01.jpg); position:absolute;top:0;right:0;bottom:0;left:0;background-repeat:no-repeat;background-size:contain;background-position:top center; z-index: -2;}
    .blcakBg{ background-image: none; background: linear-gradient(45.00deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 50%); z-index: -1;}
    .bg2{background-image: url(../assets/images/mobile/wap_banner02.jpg);}
    .bg3{background-image: url(../assets/images/mobile/wap_banner03.jpg);}
    /deep/ .el-carousel{
                .el-carousel__arrow{border:2rem solid rgba(255,255,255,0.4)}
                .el-carousel__indicator{margin:0 5rem}
                .el-carousel__button{ width: 12rem; height: 12rem; border-radius: 6rem; opacity: 0.5;}
                .el-carousel__indicator.is-active{
                    .el-carousel__button{ opacity:1;}
                }
    }
}

.p1{background-color: #F5F5F5; padding-bottom:127rem;
    .title{padding:92rem 0 0 0; height: 230rem;
        .title1{font-size:54rem;letter-spacing:-0.02rem;text-align:center;color:#333; line-height: 76rem; font-weight: 100;}
        .title2{font-size:70rem;letter-spacing:0.08rem;text-align:center;color:#333; line-height: 98rem;margin-top: 2rem;font-weight: 100;}
        .title3{font-size:26rem;letter-spacing:-0.01rem;text-align:center;color:#999; line-height: 36rem;margin-top: 10rem;font-weight: 100;}
        .title4{font-size:20rem; color:#333;margin-top: 7rem;
            img{margin-right: 13rem;}
        }

    }
    .itemsCenter{
        img{width: 698rem;}
    }
    .items{width: 670rem; margin: 0 auto; align-items: flex-start; height: 1622rem;
        .left{ width: 320rem;}
        .right{ width: 320rem;}
    }
/*     .itemDetail{width: 320rem; height: 400rem;border-radius: 40rem;background: #fff; flex-direction: column; padding:42rem 10rem 0 10rem; box-sizing: border-box;justify-content: flex-start;margin-top: 40rem;
        >img{width:180rem;}
        p:nth-child(2){font-weight:400;font-size:22rem;line-height:33rem;text-align:center;color:#252525;margin-top: 32rem;}
        p:nth-child(3){font-weight:100;font-size:20rem;line-height:26rem;text-align:center;color:#666;margin-top: 10rem;}

    } */

    .itemDetail{width: 320rem; height: 400rem;z-index: 1;margin-top: 40rem;
        .board{width: 320rem; height: 400rem;border-radius: 38rem;background: #fff; flex-direction: column; padding:42rem 10rem 0 10rem; box-sizing: border-box;justify-content: flex-start; z-index: 1;animation-name: slideInDown3; animation-duration:0.5s; animation-timing-function:ease;  
            div{ position:absolute;left: 0;right: 0; display:flex;justify-content:flex-start;align-items:center;flex-direction:column; z-index: 0;
                img{width:190rem; margin: auto;}
                p:nth-child(2){font-weight:400;font-size:22rem;line-height:33rem;text-align:center;color:#252525;margin-top: 32rem;}
                p:nth-child(3){font-weight:100;font-size:20rem;line-height:26rem;text-align:center;color:#666;margin-top: 10rem;}
            }
        }
       
        .itemShow1{animation-name: flipInY2;}
        .itemShow2{animation-name: flipOutY2;}
        div.active{ z-index: 1;}
    }
   /*  .itemDetail{
        .board:hover{box-shadow: 0 0 20px #ccc;animation-name: slideInUp3; animation-duration:0.5s; animation-timing-function:ease;animation-fill-mode: forwards;
            .itemShow1{animation-name: flipOutY2;}
            .itemShow2{animation-name: flipInY2;}
        }
    } */
}

.pageBase{ 
    .center{width: 670rem;margin: auto; 
         .right{width: 620rem; position: relative;margin: auto;
                .showImg{width: 494rem; margin: auto;}
                .bg{ position: absolute; width: 538rem; height: 538rem; border-radius: 50%; background-color: #F5F5F5; z-index:-1;left:40rem;top:120rem;}
                /deep/ .el-carousel{
                    .el-carousel__item{ display: flex;}
                    .el-carousel__indicator{margin:0 10rem}
                    .el-carousel__button{ width: 12rem; height: 12rem; border-radius: 6rem; background-color: #999; opacity: 0.5;}
                    .el-carousel__indicator.is-active{
                        .el-carousel__button{background-color: #CE1C24; opacity:1;}
                    }
                }
        }
    }
}
.p2{height: 1348rem;}
.p3{height: 1300rem;
    .center{width:750rem;margin: auto;
        .centerLeft{padding:40rem 40rem 50rem 40rem;}
        .right{width:750rem;
            .showImg{width: 750rem; margin: auto;}
        }
        .right.p4{padding: 100rem 0;}
    }
}

.copyright{font-size: 32rem;color: #999; height: 100rem; background-color: #f5f5f5; margin-bottom: 100rem;}

.line{ display: flex; justify-content: flex-start; align-content: flex-start; margin:14rem 0;
    >div{height: 8rem; border-radius: 4rem; background-color: #CE1C24;}
    .line1{ width: 88rem; margin-right: 8rem;}
    .line2{width: 8rem;}
}

.centerLeft{padding:40rem 0 50rem 0;position: relative;box-sizing: border-box;
    .title1{font-family:"HarmonyOS Sans SC Bold";font-weight:700;font-size:48rem;text-align:left;color:#e7e7e7; line-height: 56rem; }
    .title2{font-weight:700;font-size:64rem;text-align:left;color:#333; line-height: 76rem;}
    .title3{font-size:32rem;line-height:48rem;text-align:left;color:#333; margin-top: 40rem;}
    .bottomTitle{ position: absolute; top:auto; bottom:255rem; left:0;}
    .title4{font-weight:400;font-size:20rem;text-align:center;color:#333;
        img{ margin-right: 13rem;}
    }
    .title5{font-size:18rem;line-height:36rem;text-align:left;color:#999; margin-top: 20rem;}
}
 
.more{color: #e7e7e7;font-size: 48rem;padding:80rem 0 50rem 50rem;
    p{margin-bottom: 22rem;}
    p:nth-child(2){ text-indent: 48rem;}
}

.rightFixedBtn{ position: fixed; left: auto; top:auto; right: 0; bottom:36rem; z-index: 1000;height: 340rem;
    li{width: 120rem; height: 120rem;margin-bottom:16rem;background-color: rgba(0,0,0,0.5); border-radius: 10rem 0 0 10rem; font-size: 12rem;color: #fff; flex-direction: column; line-height: 1; cursor: pointer;word-break: keep-all;
        img{margin-bottom: 7rem; width: 40rem;}
    }
    li:hover{background-color: rgba(0,0,0,0.8);}
}
.online{color: #666666;
    .title{font-size:26rem;line-height:40rem;text-align:left;color:#333; display: flex; margin-bottom: 10rem;align-items: center; font-weight: bold;
        >img{height: 28rem; margin-right: 16rem;}
    }
    .txt{line-height: 28rem; text-indent: 48rem;font-size:24rem;}
    .tel{color: #CE1C24; margin-bottom: 40rem; }
}

.smty{ width: 160rem; margin-left: -20rem;}
.cgjWx{ width: 300rem; margin: auto;}

</style>
 
