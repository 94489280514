import Vue from 'vue'
import App from './App.vue'

/* import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'small', zIndex: 3000 }); */
 
import { Carousel,CarouselItem,Popover,Steps,Step} from 'element-ui'
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Steps)
Vue.use(Step)

import animated from 'animate.css' 
Vue.use(animated)


Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')
