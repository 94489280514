<template>
    <div class="pagePc">


        <ul class="rightFixedBtn">
            <el-popover   placement="left"  trigger="hover" popper-class="hidePop  resetPop animate__animated  animate__faster animate__fadeInRight2" >
                 <div class="online">
                    <p class="title"><img src="@/assets/images/icon_dianhuakefu.png" /> 24小时咨询电话</p>
                    <p class="txt tel">400-1868-555</p>
                    <p class="title hover" @click="openUrl(7)"><img src="@/assets/images/icon_zaixiankefu.png" /> 客服在线咨询</p>
                    <p class="txt hover" @click="openUrl(7)">专属客服为您服务</p>
                 </div>
                <li class="flex animate" slot="reference"><img src="@/assets/images/index_icon_weixin2.png" />在线客服</li>
            </el-popover>
            <el-popover   placement="left"  trigger="hover" popper-class="hidePop animate__animated  animate__faster animate__fadeInRight2" >
                <img class="cgjWx" src="@/assets/images/code/gzh.jpg" />
                <li class="flex animate" slot="reference"><img src="@/assets/images/index_icon_weixin.png" />公众号</li>
            </el-popover>
            <el-popover   ref="xcxPop" placement="left"  trigger="hover" popper-class="hidePop animate__animated  animate__faster animate__fadeInRight2" >
                <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                <li class="flex" slot="reference"><img src="@/assets/images/index_icon_xcx.png" />小程序</li>
            </el-popover>
            <li class="flex" v-if="scollTopBtnShow"  @click="scrollTop()"><img src="@/assets/images/index_icon_up.png" />返回顶部</li>
        </ul>


         <div class="top" id="top">
            <div class="head flex flexBetween">
                <div class="logo">
                    <img src="@/assets/images/hxcgj_logo.png" title="恒信车管家" />
                </div>
                <div class="links">
                    <div  class="linksUl flex flexRight">
                        <a href="#top" class="active">首页</a>
                        <a href="#buyCar">买车</a>
                        <a href="#MAINTENANCE">养车</a>
                        <a href="#centerLeft">车生活</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner">
            <el-carousel height="1070rem" :interval="bannerChangeTime" :loop="true" @change="bannerChange">
                <el-carousel-item>
                    <div class="bannerCenter">
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp" v-if="bannerIndex == 0">恒信车管家</h5>
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp animate__delay-2s"  v-if="bannerIndex == 0">您身边的汽车管家</h5>
                        <div class="btn flex flexLeft">
                            <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/gzh.jpg" />
                                    <div class="bannerBtn gzh flex" slot="reference"><img src="@/assets/images/index_icon_weixin.png" />关注公众号</div>
                            </el-popover>

                            <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                                    <div class="bannerBtn xcx flex"  slot="reference"><img src="@/assets/images/index_icon_xcx.png" />体验小程序</div>
                            </el-popover>
                        </div>
                    </div>
                    <div class="blcakBg"></div>
                    <div class="bg1">
                        <video ref="videoPlayer" class="Video" id="video" loop="true" muted autoplay="true" controlslist="nodownload">
                                <source src="@/assets/video/banner.mp4" type="video/mp4">
                                你的浏览器不支持HTML5 video.
                        </video>
                    </div>
                </el-carousel-item>

                <el-carousel-item>
                     <div class="bannerCenter">
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 1">特价精品车</h5>
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp animate__delay-2s"  v-if="bannerIndex == 1">超值高配低价准新车</h5>
                        <div class="btn flex flexLeft">
                             <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/gzh.jpg" />
                                    <div class="bannerBtn gzh flex" slot="reference"><img src="@/assets/images/index_icon_weixin.png" />关注公众号</div>
                            </el-popover>
                            <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                                    <div class="bannerBtn xcx flex"  slot="reference"><img src="@/assets/images/index_icon_xcx.png" />体验小程序</div>
                            </el-popover>
                        </div>
                    </div>
                    <div class="bg2"></div>
                </el-carousel-item>
                 <el-carousel-item>
                     <div class="bannerCenter">
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp"  v-if="bannerIndex == 2">恒信惠保</h5>
                        <h5 class=" animate__animated  animate__faster animate__fadeInUp animate__delay-2s"  v-if="bannerIndex == 2">超高性价比维修保养套餐</h5>
                        <div class="btn flex flexLeft">
                             <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/gzh.jpg" />
                                    <div class="bannerBtn gzh flex" slot="reference"><img src="@/assets/images/index_icon_weixin.png" />关注公众号</div>
                            </el-popover>

                            <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                                    <div class="bannerBtn xcx flex"  slot="reference"><img src="@/assets/images/index_icon_xcx.png" />体验小程序</div>
                            </el-popover>
                        </div>
                    </div>
                    <div class="bg3"></div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="body">
            <div class="p1" >
                <div class="title" >
                    <p ref="item7" v-show="item7Show" class="title1  animate__animated  animate__faster animate__fadeInUp">恒信车管家</p>
                    <p ref="item8" v-show="item7Show" class="title2  animate__animated  animate__faster animate__fadeInUp animate__delay-2s">小程序上线啦!</p>
                    <p  v-show="item7Show" class="title3  animate__animated  animate__faster animate__fadeInUp animate__delay-3s">全国领先的一站式汽车全方位服务平台</p>
                    
                        <el-popover placement="top"  trigger="hover"  popper-class="animate__animated  animate__faster animate__slideInUp2 animate__delay-4s" v-show="item7Show">
                            <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                            <p  slot="reference" class="title4 flex"><img class="smty" src="@/assets/images/index_btn_xcx.png" /> 扫码体验</p>
                        </el-popover>
                   
                </div>
                <div class="items flex flexBetween">

                    <div class="left">
                        
                        <div v-show="item1Show" ref="item1" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                            <div class="board">
                                <div :class="['itemShow1 animate__animated']">
                                            <img src="@/assets/images/chahua_zsgjhfw.png" />
                                            <p>专属管家好服务</p>
                                            <p>1对1专属管家</p> 
                                </div> 
                                <div :class="['itemShow2 animate__animated']">
                                            <img src="@/assets/images/code/601.png" />
                                            <p>扫码体验</p>
                                            <p></p>
                                </div>
                            </div>
                        </div>
                        <div v-show="item2Show" ref="item2"  class="itemDetail flex  animate__animated  animate__faster animate__fadeInUp">
                            <div class="board">
                                <div :class="['itemShow1 animate__animated']">
                                            <img src="@/assets/images/chahua_mclctmh.png" />
                                            <p>买车流程透明化</p>
                                            <p>随时随地了解买车进度</p>
                                </div> 
                                <div :class="['itemShow2 animate__animated']">
                                             <img src="@/assets/images/code/602.png" />
                                            <p>扫码体验</p>
                                            <p></p>
                                </div>
                            </div>
                        </div>
                        <div v-show="item3Show" ref="item3"  class="itemDetail flex  animate__animated  animate__faster animate__fadeInUp">
                            <div class="board">
                                <div :class="['itemShow1 animate__animated']">
                                    <img src="@/assets/images/chahua_scsjzfb.png" />
                                    <p>试乘试驾真方便</p>
                                    <p>无需到店即可预约试乘试驾</p>
                                </div> 
                                <div :class="['itemShow2 animate__animated']">
                                    <img src="@/assets/images/code/603.png" />
                                    <p>扫码体验</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="center"><img src="@/assets/images/screen_shouye.png" /></div>

                    <div class="right">
                        <div v-show="item4Show" ref="item4" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                                <div class="board">
                                    <div :class="['itemShow1 animate__animated']">
                                        <img src="@/assets/images/chahua_yybyxyh.png" />
                                        <p>预约保养享优惠</p>
                                        <p>足不出户帮您在线预约保养</p>
                                    </div> 
                                    <div :class="['itemShow2 animate__animated']">
                                        <img src="@/assets/images/code/604.png" />
                                        <p>扫码体验</p>
                                        <p></p>
                                    </div>
                                </div>   
                        </div>
                        <div v-show="item5Show" ref="item5" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                            <div class="board">
                                    <div :class="['itemShow1 animate__animated']">
                                        <img src="@/assets/images/chahua_czqyzsj.png" />
                                        <p>车主权益再升级</p>
                                        <p>提高客户车辆资产安全性</p>
                                    </div> 
                                    <div :class="['itemShow2 animate__animated']">
                                        <img src="@/assets/images/code/606.png" />
                                        <p>扫码体验</p>
                                        <p></p>
                                    </div>
                                </div>   
                        </div>
                        <div v-show="item6Show" ref="item6" class="itemDetail flex animate__animated  animate__faster animate__fadeInUp">
                            <div class="board">
                                    <div :class="['itemShow1 animate__animated']">
                                        <img src="@/assets/images/chahua_ycxftmh.png" />
                                        <p>养车消费透明化</p>
                                        <p>维修保养车辆消费透明化</p>
                                    </div> 
                                    <div :class="['itemShow2 animate__animated']">
                                        <img src="@/assets/images/code/605.png" />
                                        <p>扫码体验</p>
                                        <p></p>
                                    </div>
                                </div>   
                        </div>
                    </div>

                </div>
            </div>

            <div class="p2" id="buyCar">
                <div class="center" v-show="item9Show">
                    <div class="left centerLeft" ref="item9"  >
                        <h5 class="title1  animate__animated  animate__faster animate__fadeInUp">BUY</h5>
                        <h5 class="title1  animate__animated  animate__faster animate__fadeInUp">CAR</h5>
                        <div class="line   animate__animated  animate__faster animate__fadeInUp animate__delay-1s">
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                        <h5 class="title2  animate__animated  animate__faster animate__fadeInUp animate__delay-2s">特惠买车</h5>
                        <p class="title3 animate__animated  animate__faster animate__fadeInUp animate__delay-3s" v-if="activeTab == 0">国内领先的大型汽车经销集团，超300家汽车4S门店，为您提供专业为消费者提供新车、维保、个性化改装、精品定制、汽车美容的服务</p>
                        <p class="title3 animate__animated  animate__faster animate__fadeInUp animate__delay-3s" v-if="activeTab == 1">超值特价精品车，不仅年份新，车况精品，超低公里数，刚过车辆磨合期。9.9成新的准新车品质，全系车辆已消毒，放心购买</p>
                        <p class="title3 animate__animated  animate__faster animate__fadeInUp animate__delay-3s" v-if="activeTab == 2">经过国家标准二手车检测合格，买车、卖车、选车、提车，全环节100%放心省心安心，买卖二手车，首选恒信二手车</p>
                        <div class="bottomTitle" >
                            
                                <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop" >
                                    <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                                    <div  slot="reference"  class="title4 flex flexLeft"><img class="smty" src="@/assets/images/index_btn_xcx.png" /> 扫码体验</div>
                                </el-popover>
                            
                            <p class="title5">更新、更全、更实惠的汽车报价</p>
                        </div>
                        
                    </div>
                    <div class="right flex flexBetween">
                        <div class="img flex">
                            <!-- <img src="@/assets/images/screen_xinche.png" v-show="activeTab == 0" />
                            <img src="@/assets/images/screen_tejiache.png" v-show="activeTab == 1"/>
                            <img src="@/assets/images/screen_ershouche.png" v-show="activeTab == 2"/> -->
                            <el-carousel ref="imgActive" height="900rem" indicator-position="outside" :loop="true" :interval="5000" @change="imgActiveChange">
                                <el-carousel-item> <img class="img2" src="@/assets/images/screen_xinche.png" /> </el-carousel-item>
                                <el-carousel-item> <img class="img2" src="@/assets/images/screen_tejiache.png" /> </el-carousel-item>
                                <el-carousel-item> <img class="img2" src="@/assets/images/screen_ershouche.png" /> </el-carousel-item>
                                   
                            </el-carousel>
                            <div class="bg"></div>
                        </div>
                        <div class="tab">
                            <el-steps direction="vertical" :active="activeTab"  >
                                <el-step title="新车销售" icon="tabIcon" @click.native="handleStep(0)"></el-step>
                                <el-step title="特价精品车" icon="tabIcon" @click.native="handleStep(1)"></el-step>
                                <el-step title="恒信二手车" icon="tabIcon" @click.native="handleStep(2)"></el-step>
                            </el-steps>
                        </div>

                    </div>
                </div>
            </div>

            <div class="p3" id="MAINTENANCE">
                <div class="center">
                    <div class="left centerLeft " ref="item10" v-show="item10Show">
                        <h5 class="title1 animate__animated  animate__faster animate__fadeInUp">MAINTENANCE</h5>
                        <h5 class="title1 animate__animated  animate__faster animate__fadeInUp">CAR</h5>
                        <div class="line  animate__animated  animate__faster animate__fadeInUp animate__delay-1s">
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                        <h5 class="title2 animate__animated  animate__faster animate__fadeInUp animate__delay-2s">安心养车</h5>
                        <p v-show="item10Show" class="title3 animate__animated  animate__faster animate__fadeInUp  animate__delay-3s" >在线预约，方便快捷，专享折扣回馈车管家会员专享特惠保养套餐，一键购入</p>
                        <div class="bottomTitle" >
                            <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                                    <div  slot="reference"  class="title4 flex flexLeft"><img class="smty" src="@/assets/images/index_btn_xcx.png" /> 扫码体验</div>
                                </el-popover>
                            <p class="title5">这里有恒信汽车集团最全的线上服务</p>
                        </div>
                    </div>
                    <div class="right">
                        <el-carousel height="732rem" indicator-position="outside" :loop="true">
                            <el-carousel-item><img class="img" src="@/assets/images/screen_yangche01.png" /></el-carousel-item>
                            <el-carousel-item><img class="img" src="@/assets/images/screen_yangche02.png" /></el-carousel-item>
                            <el-carousel-item><img class="img" src="@/assets/images/screen_yangche03.png" /></el-carousel-item>      
                        </el-carousel>
                    </div>
                </div>
            </div>

            <div class="p4" id="centerLeft">
                <div class="center">
                    <div class="left centerLeft " ref="item11" v-show="item11Show">
                        <h5 class="title1 animate__animated  animate__faster animate__fadeInRight">CAR</h5>
                        <h5 class="title1 animate__animated  animate__faster animate__fadeInRight">LIFE</h5>
                        <div class="line animate__animated  animate__faster animate__fadeInRight animate__delay-1s">
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                        <h5 class="title2  animate__animated  animate__faster animate__fadeInRight animate__delay-2s">恒信车生活</h5>
                        <p class="title3 animate__animated  animate__faster animate__fadeInRight animate__delay-3s" v-show="item11Show" >车主每日抽奖领好礼，积分兑换超值商品，享受多项车主福利。车友圈炫新车、聊故事、谈改装，打造车友社交新方式！</p>
                        <div class="bottomTitle" >
                            <el-popover placement="top"  trigger="hover" popper-class="animate__animated  animate__faster animate__slideInUp2 erweimaPop">
                                    <img class="cgjWx" src="@/assets/images/code/xcx.jpg" />
                                    <div  slot="reference"  class="title4 flex flexLeft"><img class="smty" src="@/assets/images/index_btn_xcx.png" /> 扫码体验</div>
                                </el-popover>
                            <p class="title5">汽车生活一体化服务平台</p>
                        </div>
                    </div>
                    <div class="right">
                         <img class="img" src="@/assets/images/screen_cheshenghuo.png" />
                    </div>
                </div>
            </div>


        </div>
        <div class="foot">
            <div class="center flex flexBetween">
                <div class="left">
                        <!--<div class="webLinks"><a href="/">关于我们</a> | <a href="/">服务条款</a> | <a href="/">隐私协议</a> | <a href="/">联系我们</a> </div> -->                    <div class="otherLinks">
                        <span>友情链接：</span> 
                        <a href="https://www.hxqcjt.com/" target="_blank">恒信汽车集团</a>
                        <a href="http://www.hbhxdl.com/" target="_blank">恒信德龙</a>
                        <a href="https://cn-ne.cn/" target="_blank">楚能新能源</a>
                        <a href="https://www.scotec.net/" target="_blank">星云海数字科技</a>
                        <a href="https://www.autohome.com.cn/" target="_blank">汽车之家</a>
                        <a href="https://www.dongchedi.com/" target="_blank">懂车帝</a>
                        <a href="https://www.yiche.com/" target="_blank">易车网</a>
                        <a href="https://www.pcauto.com.cn/" target="_blank">太平洋汽车网</a>
                        <a href="http://bank.pingan.com/" target="_blank">平安银行</a>
                        <a href="https://www.guazi.com/" target="_blank">瓜子二手车</a>
                    </div>
                    <div class="copyRight">Copyright © 2023 恒信汽车集团 版权所有 鄂公网安备 <img src="@/assets/images/icon_beian.png" />42010502000692号 <img src="@/assets/images/icon_guohui.png" /> 鄂ICP备18010073号-6</div>
                </div>
                <div class="right"><img src="@/assets/images/hxcq_logo.png"  title="恒信汽车" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'animate.css';
export default {
  name: 'pagePc',
  data(){
    return{
        activeTab:0,
        bannerIndex:0,
        bannerChangeTime:16000,

        item1Show: false,
        item2Show: false,
        item3Show: false,
        item4Show: false,
        item5Show: false,
        item6Show: false,
        item7Show: false,
        item8Show: false,
        item9Show: false,
        item10Show: false,
        item11Show: false,

        isScrolling:false,
        timerT:null,
        timer2:null,
        scollTopBtnShow:false,
        showXcx:true,
        openLinks:[
            'https://mobile.hxqc.com/j/ic',
            'https://mobile.hxqc.com/j/mc',
            'https://mobile.hxqc.com/j/kc',
            'https://mobile.hxqc.com/j/yc',
            'https://mobile.hxqc.com/j/rc',
            'https://mobile.hxqc.com/j/tc',
            'https://mobile.hxqc.com/j/sc',
            'https://chat.pacss.hxqcgf.com/chat/mobile?noCanClose=1&token=3e863cf73eb77f2ce8774fbaa26ebef1'
        ]
        
    }
  },
  beforeDestroy(){
  },
  mounted(){
 
    
                var docEl = document.documentElement,
                resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
                recalc = function () {
                    var clientWidth = docEl.clientWidth;
                    if (clientWidth > 1903) {
                        clientWidth = 1903;
                        docEl.style.fontSize = 67.5 + 'px';
                    }
                    if (!clientWidth) return;
                    docEl.style.fontSize =  (clientWidth / 1903) + 'px';
                };
                recalc();
                if (!document.addEventListener) return;
                window.addEventListener(resizeEvt, recalc, false);
                document.addEventListener('DOMContentLoaded', recalc, false);

            window.addEventListener('scroll', this.handleScroll)
            this.handleScroll()

            this.$refs.xcxPop.showPopper = true
            this.timer2 = setTimeout(()=>{
                this.$refs.xcxPop.showPopper = false
                clearTimeout(this.timer2)
                this.timer2 = null
            },5000)
  },
  methods:{
    openUrl(index){
        window.open(this.openLinks[index])
    },
    bannerChange(e){
        this.bannerIndex = e
        if (e == 0){
            this.$refs.videoPlayer.load()
            this.bannerChangeTime = 16000
        }else{
            this.bannerChangeTime = 5000
        }
    },
    scrollTop(){
        let i = 10
        var timer= setInterval(function () {
            if (document.documentElement.scrollTop != 0) {
                document.documentElement.scrollTop -= (100 + i);
                i = i+10
             } else {
                clearInterval(timer);
            }
        }, 10);
    },
    handleScroll () {
        let topVal = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop 
        if (topVal > 1000){
            this.scollTopBtnShow = true
        }else{
            this.scollTopBtnShow = false
        }

        if (this.isScrolling == false){
            this.isScrolling = true
            // 实现当滚动到指定位置，触发动画
            // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
            // this.gdjz('earth', 'earth animated bounceInDown', 50)
            let _this = this
            let refArray = [
                    {ref: 'item1', show: 'item1Show'},
                    {ref: 'item2', show: 'item2Show'},
                    {ref: 'item3', show: 'item3Show'},
                    {ref: 'item4', show: 'item4Show'},
                    {ref: 'item5', show: 'item5Show'},
                    {ref: 'item6', show: 'item6Show'},
                    {ref: 'item7', show: 'item7Show'},
                    {ref: 'item8', show: 'item8Show'},
                    {ref: 'item9', show: 'item9Show'},
                    {ref: 'item10', show: 'item10Show'},
                    {ref: 'item11', show: 'item11Show'},
            ]
            refArray.forEach((r) => {
                    _this.gdjz(r.ref, 150, () => {
                        _this[r.show] = true
                    },()=>{
                        _this[r.show] = false
                    })
            })

            clearTimeout(this.timerT)
            this.timerT = setTimeout(()=>{
                this.isScrolling = false
            },100)
             
        }
    },
    gdjz (div, offset, show,hide) {
        let dom = this.$refs[div] // 等同于document.querySelector('.earth') true
        if(dom){
            var a,b,c,d;
            d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
            a = eval(d + offset)
            b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
            c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
            if( b + c > a ) {
                show && show()
            }else{
                hide && hide()
            }
        }
    },

    handleStep(index){
        this.activeTab = index
        this.$refs.imgActive.setActiveItem(index)
    },
    imgActiveChange(index){
        this.activeTab = index
    },
  }
}
</script>
<style>
:root {
  --animate-delay: 0.15s;
}
@-webkit-keyframes slideInUp2 {
  from {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp2 {
  from {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInUp2 {
  -webkit-animation-name: slideInUp2;
  animation-name: slideInUp2;
}
@-webkit-keyframes fadeInRight2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInRight2 {
  -webkit-animation-name: fadeInRight2;
  animation-name: fadeInRight2;
}

@keyframes flipInY2 {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
 @keyframes flipOutY2 {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  50% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 0;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

@keyframes slideInUp3 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}
@keyframes slideInDown3 {
  from {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.el-popover{display:flex;justify-content:center;align-items:center; padding:12rem;box-sizing:border-box;width: 180rem;height: 180rem;min-width: 100rem;}
.resetPop{ width: 180rem; height: 140rem;border-radius: 10rem;}
.hidePop.fade-in-linear-leave-to{ opacity: 0 !important;}
</style>
<style lang="less" scoped>
 
.pagePc{  margin: auto;}
.top{
        .head{width:1600rem; margin: auto;padding:18rem 0 14rem 0;
            .logo{img{width: 124rem;}}
            .links{
                .linksUl{
                    a{ color:#333; margin-left:80rem;font-size: 18rem; font-weight: 400; border-bottom:2rem solid #fff;line-height: 1; padding-bottom:10rem; cursor: pointer; text-decoration: none;}
                    a:hover,a.active{color: #CE1C24; border-bottom: 2rem solid #CE1C24;}
                }
            }
        }
}
.online{color: #666666;
    .title{font-size:13rem;line-height:20rem;text-align:left;color:#333; display: flex; margin-bottom: 5rem;align-items: center; font-weight: bold;
        >img{height: 14rem; margin-right: 8rem;}
    }
    .txt{line-height: 14rem; text-indent: 24rem;font-size:12rem;}
    .tel{color: #CE1C24; margin-bottom: 20rem; }
    .hover{ cursor: pointer;}
}

.Video{ width:100%; max-width: 1920rem; min-width: 1600rem;}
.banner{ width:100%; max-width: 1920rem; min-width: 1600rem;height: 1070rem; margin: auto; overflow: hidden; 
    .bannerCenter{color: #fff; width: 1600rem; margin: 300rem auto 0 auto; z-index: 1;
        h5{line-height: 1; font-size: 72rem; margin-bottom: 30rem;letter-spacing:5rem;}
        .btn{
            .bannerBtn{width: 186rem; height: 60rem;border-radius: 30rem;background: transparent;border: 1rem solid #fff;font-size: 16rem; font-weight: 100;margin-right:40rem; margin-top: 150rem;cursor: pointer;
                >img{margin-right: 10rem;}
            }
            .gzh{background: linear-gradient(270.00deg, #ce1c24 0%, #950b11 100%);border: 1rem solid #CE1C24;}
        }
    }
    .bg1,.bg2,.bg3,.bg4,.blcakBg{ width: 1920rem; background-image: url(../assets/images/banner01.jpg); position:absolute;top:0;right:0;bottom:0;left:0;background-repeat:no-repeat;background-size:cover;background-position:top center; z-index: -2;}
    .blcakBg{ background-image: none; background: linear-gradient(90.00deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 50%); z-index: -1;}
    .bg1{background-image: none;}
    .bg2{background-image: url(../assets/images/banner02.jpg);}
    .bg3{background-image: url(../assets/images/banner03.jpg);}
    /deep/ .el-carousel{
                .el-carousel__arrow{border:2rem solid rgba(255,255,255,0.4)}
                .el-carousel__indicator{margin:0 5rem}
                .el-carousel__button{ width: 12rem; height: 12rem; border-radius: 6rem; opacity: 0.5;}
                .el-carousel__indicator.is-active{
                    .el-carousel__button{ opacity:1;}
                }
    }
}

.p1{background-color: #F5F5F5; padding-bottom:127rem;
    .title{padding:128rem 0 80rem 0;height:286rem;
        .title1{font-size:46rem;letter-spacing:-0.02rem;text-align:center;color:#333; line-height: 65rem; font-weight: 100;}
        .title2{font-size:60rem;letter-spacing:0.08rem;text-align:center;color:#333; line-height: 84rem;margin-top: 7rem;font-weight: 100;}
        .title3{font-size:23rem;letter-spacing:-0.01rem;text-align:center;color:#999; line-height: 32rem;margin-top: 7rem;font-weight: 100;}
        .title4{font-size:20rem; color:#333; margin: auto;margin-top: 7rem; width: 180rem;
            img{margin-right: 13rem;}
        }

    }
    .items{ height: 1036rem; width: 1824rem; margin: 0 auto; align-items: flex-start;
        .left{ width: 618rem; min-height: 900rem;
            .itemDetail:nth-child(1){float:left; margin-top: 250rem;}
            .itemDetail:nth-child(2){float:right; margin-top: 90rem;}
            .itemDetail:nth-child(3){float:right;margin-top: 60rem;}
        }

        .center{ width: 598rem;
            >img{ width: 598rem;}
        }
        .right{ width: 618rem;min-height: 900rem;
            .itemDetail:nth-child(1){float:left; margin-top: 90rem;}
            .itemDetail:nth-child(2){float:right; margin-top: 250rem;}
            .itemDetail:nth-child(3){float:left;margin-top: 60rem;}
        }
    }
    .itemDetail{width: 273rem; height: 355rem;z-index: 1;
        .board{width: 273rem; height: 355rem;border-radius: 38rem;background: #fff; flex-direction: column; padding:42rem 10rem 0 10rem; box-sizing: border-box;justify-content: flex-start; z-index: 1;animation-name: slideInDown3; animation-duration:0.5s; animation-timing-function:ease;  
            div{ position:absolute;left: 0;right: 0; display:flex;justify-content:flex-start;align-items:center;flex-direction:column; z-index: 0;
                img{width:190rem; margin: auto;}
                p:nth-child(2){font-weight:400;font-size:22rem;line-height:33rem;text-align:center;color:#252525;margin-top: 32rem;}
                p:nth-child(3){font-weight:100;font-size:20rem;line-height:26rem;text-align:center;color:#666;margin-top: 10rem;}
            }
        }
       
        .itemShow1{animation-name: flipInY2;}
        .itemShow2{animation-name: flipOutY2;}
        div.active{ z-index: 1;}
    }
    .itemDetail{
        .board:hover{box-shadow: 0 0 20px #ccc;animation-name: slideInUp3; animation-duration:0.5s; animation-timing-function:ease;animation-fill-mode: forwards;
            .itemShow1{animation-name: flipOutY2;}
            .itemShow2{animation-name: flipInY2;}
        }
    }
}

.line{ display: flex; justify-content: flex-start; align-content: flex-start; margin:14rem 0;
    >div{height: 8rem; border-radius: 4rem; background-color: #CE1C24;}
    .line1{ width: 88rem; margin-right: 8rem;}
    .line2{width: 8rem;}
}

.centerLeft{padding-top:84rem;position: relative;box-sizing: border-box;
    .title1{font-family:"HarmonyOS Sans SC Bold";font-weight:700;font-size:50rem;text-align:left;color:#e7e7e7; line-height: 60rem; }
    .title2{font-weight:700;font-size:60rem;text-align:left;color:#333; line-height: 70rem; margin-top: 40rem;}
    .title3{font-size:18rem;line-height:36rem;text-align:left;color:#333; margin-top: 60rem;}
    .bottomTitle{ position: absolute; top:auto; bottom:255rem; left:0;}
    .title4{font-weight:400;font-size:20rem;text-align:center;color:#333;width: 180rem;
        img{ margin-right: 13rem;}
    }
    .title5{font-size:18rem;line-height:36rem;text-align:left;color:#999; margin-top: 20rem;}
}

.p2{ height: 1080rem; 
    .center{width: 1600rem;margin: auto;display: flex;justify-content: space-between;align-items: flex-start;
        .left{width: 500rem; height: 1080rem; }
        .right{ width: 940rem;padding-top:110rem;
            .img{width: 620rem; position: relative;
                .img2{ width: 511rem; height: 900rem; margin: auto;}
                .bg{ position: absolute; width: 620rem; height: 620rem; border-radius: 50%; background-color: #F5F5F5; z-index:-1;left:0;top:120rem;}
            }
            /deep/ .el-carousel{width: 620rem;
                .el-carousel__item{ text-align: center;}
                .el-carousel__indicator{margin:0 20rem}
                .el-carousel__button{ width: 12rem; height: 12rem; border-radius: 6rem; background-color: #999; opacity: 0.5;}
                .el-carousel__indicator.is-active{
                    .el-carousel__button{background-color: #CE1C24; opacity:1;}
                }
            }
            .tab{width: 200rem; height: 300rem;
                /deep/ .el-steps{
                    .tabIcon{ display:inline-block;width:12rem;height:12rem;border-radius:50%;border:4rem solid #CE1C24; opacity: 0.5;}
                    .el-step__head.is-finish{border-color:#CE1C24 !important; color:#CE1C24 !important;}
                    .el-step__line{background-color:#CE1C24; opacity: 0.5;}
                    .el-step__title{color:#666 !important; cursor: pointer;}
                    .el-step__title.is-process{color:#CE1C24 !important;}
                    .el-step__head.is-process{
                        .tabIcon{opacity: 1;}
                    }
                }
                 
            }

        }
    }
}
.p3{height: 1080rem; background-color:#F5F5F5 ;
    .center{width: 1600rem;margin: auto;display: flex;justify-content: space-between;align-items: flex-start;
        .left{width:410rem; height: 1080rem;  }
        .right{width: 1100rem; height: 732rem; padding-top:140rem;
            .img{width:1107rem;}
            /deep/ .el-carousel{
                .el-carousel__indicator{margin:0 20rem}
                .el-carousel__button{ width: 12rem; height: 12rem; border-radius: 6rem; background-color: #999; opacity: 0.5;}
                .el-carousel__indicator.is-active{
                    .el-carousel__button{background-color: #CE1C24; opacity:1;}
                }
            }
        }
    }
}
.p4{height: 1080rem;
    .center{width: 1600rem;margin: auto;display: flex;justify-content: space-between;align-items: flex-start;
        .left{width:410rem; height: 1080rem;  }
        .right{width: 1100rem; height: 732rem; padding-top:140rem;
            .img{width: 1100rem;}
        }
    }
}
.foot{ background-color:#f5f5f5; /* padding-top:45rem; */
    a,a:hover{color: #333;text-decoration-line: none; line-height: 1;}
    .center{ width: 1600rem; margin: 0 auto;align-items: flex-start;}
    .webLinks{ color: #E1E1E1;font-size: 18rem;
        a{margin:0 37rem;}
        :nth-child(1){margin-left: 0;}
    }
    .otherLinks{color:#999; margin: 40rem 0; font-size: 16rem;
        a{color:#999;margin:0 20rem;}
        :nth-child(2){margin-left: 0;}
    }
    .copyRight{color:#999; margin: 40rem 0; font-size: 16rem;
        img{ margin: 0 5rem 0 30rem;}
    }
    .right{margin-top:40px;img{width: 124rem;}}
}
.rightFixedBtn{ position: fixed; left: auto; top:auto; right: 36rem; bottom:36rem; z-index: 1000;height: 300rem;
    li{width: 60rem; height: 60rem;margin-bottom:16rem;background-color: rgba(0,0,0,0.5);font-size: 12rem;color: #fff; flex-direction: column; line-height: 1; cursor: pointer;word-break: keep-all;
        img{margin-bottom: 7rem; width: 24rem;}
    }
    li:hover{background-color: rgba(0,0,0,0.8);}
}
.smty{ width: 80rem; margin-left: -10rem;}
.cgjWx{ width: 150rem; margin: auto;}

</style>
 
